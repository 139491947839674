import React, { useEffect, useState } from 'react';
import { fetchMetrics } from '../utils/solanaAPI';
import { fetchDetailedMetrics } from '../utils/solanaAPI';
import SankeyChart from './SankeyChart';

const fetchEpochInfo = async () => {
  try {
    const response = await fetch('https://rpc.owlnet.dev', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        jsonrpc: '2.0',
        id: 1,
        method: 'getEpochInfo',
        params: [],
      }),
    });

    const data = await response.json();
    return {
      epoch: data.result.epoch,
      currentSlot: data.result.absoluteSlot,
      transactionCount: data.result.transactionCount || 'N/A',
    };
  } catch (error) {
    console.error('Error fetching epoch info:', error);
    return null;
  }
};

const Dashboard = () => {
  const [epochInfo, setEpochInfo] = useState({
    epoch: 'Loading...',
    currentSlot: 'Loading...',
    transactionCount: 'Loading...',
  });

  const [tpuMetrics, setTPUMetrics] = useState({
    malformed: 0,
    buffered: 0,
    duplicated: 0,
    packed: 0,
    received: 0,
  });

  const [detailedMetrics, setDetailedMetrics] = useState({
    quic: 0,
    udp: 0,
    received: 0,
    malformed: 0,
    abandoned: 0,
    verify: 0,
    unparseable: 0,
    duplicate: 0,
    dedup: 0,
    resolve: 0,
    buffered: 0,
    badLUT: 0,
    packed: 0,
  });

  // Fetching data and updating state
  useEffect(() => {
    const fetchData = async () => {
      const metrics = await fetchDetailedMetrics();
      console.log('Fetched metrics:', metrics);
      if (metrics) {
        setDetailedMetrics(metrics);
      }
    };

fetchData();
}, []);;

  useEffect(() => {
    const updateEpochInfo = async () => {
      const info = await fetchEpochInfo();
      if (info) {
        setEpochInfo(info);
      }
    };
    updateEpochInfo(); // Fetch data immediately on mount
    const interval = setInterval(updateEpochInfo, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  useEffect(() => {
    const fetchTPUMetricsData = async () => {
      try {
        const metrics = await fetchMetrics();
        setTPUMetrics(metrics);
      } catch (error) {
        console.error('Error fetching TPU metrics:', error);
      }
    };

    fetchTPUMetricsData();
  }, []); // Only run once on mount

  return (
    <>
      <div className="p-4">
        <h2 className="text-black text-2xl font-bold mb-4">X1 Dashboard</h2>
        <p>Epoch: {epochInfo.epoch}</p>
        <p>Current Slot: {epochInfo.currentSlot}</p>
        <p>Transaction Count: {epochInfo.transactionCount}</p>
        <p>QUIC: {detailedMetrics.quic}</p>
        <p>UDP: {detailedMetrics.udp}</p>
        <p>Received: {detailedMetrics.received}</p>
        <p>Malformed: {detailedMetrics.malformed}</p>
        <p>Verify: {detailedMetrics.verify}</p>
        <p>Abandoned: {detailedMetrics.abandoned}</p>
        <p>Unparseable: {detailedMetrics.unparseable || 0}</p>
        <p>Duplicate: {detailedMetrics.duplicate || 0}</p>
        <p>Dedup: {detailedMetrics.dedup}</p>
        <p>Resolve: {detailedMetrics.resolve}</p>
        <p>Buffered: {detailedMetrics.buffered}</p>
        <p>Bad LUT: {detailedMetrics.badLUT || 0}</p>
        <p>Packed: {detailedMetrics.packed || 0}</p>
        <p>Duplicated: {tpuMetrics.duplicated || 0}</p>
        <p>Packed: {tpuMetrics.packed || 0}</p>
        <p>Received: {tpuMetrics.received || 0}</p>
        {/* Add other metrics and components here */}
      </div>
      <SankeyChart metrics={detailedMetrics} />
    </>
  );
};

export default Dashboard;
