import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import * as d3 from 'd3';
import HeroSection from './components/HeroSection';
import EpochInfo from './components/EpochInfo';
import Network from './components/Network';
import LeaderStatus from './components/LeaderStatus';
import Dashboard  from './components/Dashboard';
import './App.css';
import { Buffer } from "buffer";
window.Buffer = Buffer;
function App() {
 return (
    <div>

      {/* Navigation Buttons */}
      <div className="header-buttons">
        <Link to="/gossip">
          <button className="header-button">Gossip</button>
        </Link>
        <Link to="/">
          <button className="header-button">Voting</button>
        </Link>
      </div>  
    <HeroSection />
         
      <div className="epoch-info">
    <h2>Epoch Information</h2>
  </div>
<EpochInfo/>

<div className="App">
<div className="chart-wrapper w-full max-w-7xl mx-auto">
      <Network />  
 <LeaderStatus />
<Dashboard />
</div>
      </div>
      
    </div>
  );
};

export default App;

