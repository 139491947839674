import React, { useState, useEffect } from 'react';
import { CheckCircle, XCircle, Clock } from 'lucide-react';
import { getRecentPerformanceSamples } from '../utils/solanaAPI'; // Import the API function

const VotingPage = ({ validatorPubkey }) => {
  const [votingInfo, setVotingInfo] = useState({
    slots: [],
    validatorInfo: {
      pubkey: '',
      version: '',
      stake: '',
      ipAddress: ''
    }
  });

  // Fetch voting information
  const fetchVotingInfo = async () => {
    try {
      const data = await getRecentPerformanceSamples(validatorPubkey);
      if (data) {
        setVotingInfo(data); // Update the state with the fetched data
      }
    } catch (error) {
      console.error('Error fetching voting info:', error);
    }
  };

  // Update every 1 second
  useEffect(() => {
    fetchVotingInfo();
    const interval = setInterval(fetchVotingInfo, 1000); // Update every 1 second
    return () => clearInterval(interval); // Clear the interval on unmount
  }, [validatorPubkey]);

  return (
    <div className="bg-gray-900 text-white p-4 rounded-lg">
      {/* Validator Info Header */}
      <div className="flex items-center space-x-4 mb-4">
        <div className="flex-1">
          <h3 className="text-xl font-semibold mb-1">{votingInfo.validatorInfo.pubkey}</h3>
          <div className="text-gray-400 text-sm">
            <span>{votingInfo.validatorInfo.version}</span>
            <span className="mx-2">•</span>
            <span>{votingInfo.validatorInfo.stake}</span>
            <span className="mx-2">•</span>
            <span>{votingInfo.validatorInfo.ipAddress}</span>
          </div>
        </div>
      </div>

      {/* Voting Table */}
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-gray-400 text-sm">
              <th className="text-left px-4 py-2">Slot</th>
              <th className="text-left px-4 py-2">Votes</th>
              <th className="text-left px-4 py-2">Non-votes</th>
              <th className="text-left px-4 py-2">Fees</th>
              <th className="text-left px-4 py-2">Duration</th>
              <th className="text-left px-4 py-2">Compute Units</th>
              <th className="text-left px-4 py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {votingInfo.slots.map((slot) => (
              <tr key={slot.number} className="border-t border-gray-800">
                <td className="px-4 py-2">{slot.number}</td>
                <td className="px-4 py-2">{slot.votes}</td>
                <td className="px-4 py-2">{slot.nonVotes}</td>
                <td className="px-4 py-2">{slot.fees}</td>
                <td className="px-4 py-2">{slot.duration} ms</td>
                <td className="px-4 py-2">{slot.computeUnits}</td>
                <td className="px-4 py-2">
                  {slot.status === 'confirmed' && <CheckCircle className="text-green-500" size={16} />}
                  {slot.status === 'pending' && <Clock className="text-yellow-500" size={16} />}
                  {slot.status === 'failed' && <XCircle className="text-red-500" size={16} />}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VotingPage;
