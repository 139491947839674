import React, { useState, useEffect } from "react";
import { sankey, sankeyLinkHorizontal } from "d3-sankey";

const SankeyChart = ({ metrics }) => {
  const [updatedMetrics, setUpdatedMetrics] = useState(metrics);

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdatedMetrics((prevMetrics) => ({
        ...prevMetrics,
        quick: prevMetrics.quick + Math.floor(Math.random() * 10),
        udp: prevMetrics.udp + Math.floor(Math.random() * 10),
      }));
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const createSankeyChart = (metrics) => {
    const graph = {
      nodes: [
        { name: `QUICK (${metrics.quick})` },
        { name: `UDP (${metrics.udp})` },
        { name: `Received (${metrics.received})` },
        { name: `Malformed (${metrics.malformed})` },
        { name: `Abandoned (${metrics.abandoned})` },
        { name: `Verify (${metrics.verify})` },
        { name: `Unparseable (${metrics.unparseable})` },
        { name: `Duplicate (${metrics.duplicate})` },
        { name: `Dedup (${metrics.dedup})` },
        { name: `Resolve (${metrics.resolve})` },
        { name: `Buffered (${metrics.buffered})` },
        { name: `Bad LUT (${metrics.bad_lut})` },
        { name: `Packed (${metrics.packed})` },
      ],
      links: [
        { source: 0, target: 2, value: metrics.quick },
        { source: 1, target: 2, value: metrics.udp },
        { source: 2, target: 3, value: metrics.received },
        { source: 3, target: 4, value: metrics.malformed },
        { source: 4, target: 5, value: metrics.abandoned },
        { source: 5, target: 6, value: metrics.verify },
        { source: 6, target: 7, value: metrics.unparseable },
        { source: 7, target: 8, value: metrics.duplicate },
        { source: 8, target: 9, value: metrics.dedup },
        { source: 9, target: 10, value: metrics.resolve },
        { source: 10, target: 11, value: metrics.buffered },
        { source: 11, target: 12, value: metrics.bad_lut },
      ],
    };

    const chartWidth = 1000;
    const chartHeight = Math.max(500, graph.nodes.length * 40);

    const { nodes, links } = sankey()
      .nodeWidth(30)
      .nodePadding(20)
      .size([chartWidth, chartHeight])(graph);

    // Create gradient
    const gradientId = "purpleRedGradient";

    return (
      <svg width={chartWidth} height={chartHeight} style={{ marginBottom: "20px" }}>
        <defs>
          <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#6a0dad" />
            <stop offset="50%" stopColor="#e75480" />
            <stop offset="100%" stopColor="#ff0000" />
          </linearGradient>
        </defs>
        {/* Links */}
        {links.map((link, i) => (
          <g key={i}>
            <path
              d={sankeyLinkHorizontal()(link)}
              style={{
                fill: "none",
                stroke: `url(#${gradientId})`,
                strokeWidth: Math.max(2, link.value), // Increased width
                opacity: 0.7,
              }}
            />
          </g>
        ))}
        {/* Nodes */}
        {nodes.map((node, i) => (
          <g key={i} transform={`translate(${node.x0},${node.y0})`}>
            <rect
              width={node.x1 - node.x0}
              height={node.y1 - node.y0}
              style={{
                fill: `url(#${gradientId})`,
                stroke: "#000",
                strokeWidth: "1px",
              }}
            />
            <text
              x={(node.x1 - node.x0) / 2}
              y={node.y0 - 10}
              textAnchor="middle"
              style={{ fontSize: "12px", fill: "#000" }}
            >
              {node.name}
            </text>
          </g>
        ))}
      </svg>
    );
  };

  return (
    <div style={{ padding: "20px" }}>
      <h3>TPU Metrics Sankey Flow</h3>
      {createSankeyChart(updatedMetrics)}
    </div>
  );
};
export default SankeyChart;  
