import React, { useState, useEffect } from 'react';

const API_URL = 'https://rpc.owlnet.dev'; // Use the same domain as the backend

const VotingData = () => {
    const [logs, setLogs] = useState([]);
    const [error, setError] = useState(null);

    const fetchLogs = async () => {
        try {
            console.log('Fetching data...');
            const response = await fetch(`${API_URL}/api/voting-data`);
            if (!response.ok) throw new Error(`Server error: ${response.status}`);

            const data = await response.json();
            console.log('Data fetched:', data);

            setLogs(data.logs);
            setError(null);
        } catch (err) {
            console.error('Fetch error:', err);
            setError(err.message);
        }
    };

    useEffect(() => {
        fetchLogs(); // Initial fetch
        const interval = setInterval(fetchLogs, 500); // Poll every 5 seconds
        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            {error ? (
                <p>Error: {error}</p>
            ) : (
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Slot</th>
                            <th>Votes</th>
                            <th>Num Nodes</th>
                            <th>Raw Log</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs.map((log) => (
                            <tr key={log.slot} style={{ textAlign: 'center', padding: '10px' }}>
                                <td>{log.slot || 'N/A'}</td>
                                <td>{log.votes || 'N/A'}</td>
                                <td>{log.num_nodes || 'N/A'}</td>
                                <td>{log.raw_log || 'N/A'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default VotingData;
