import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GossipInfo from './components/GossipInfo';
import VotingPage from './components/VotingPage';
import VotingData from './components/VotingData';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {/* Default route to render the App */}
        <Route path="/" element={<App />} />
        {/* GossipInfo route */}
        <Route path="/gossip" element={<GossipInfo />} />
        <Route path="/voting" element={<VotingPage />} />
         <Route path="/votingdata" element={<VotingData />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
