import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

const RPC_URL = 'https://rpc.owlnet.dev';

const Network = () => {
  const [networkData, setNetworkData] = useState({
    totalValidators: 0,
    totalStake: 0,
    currentTPS: 0,
    tpsHistory: [],
    error: '',
  });

  const fetchNetworkData = async () => {
    try {
      const voteAccountsResponse = await fetch(RPC_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'getVoteAccounts',
          params: [],
        }),
      });
      const voteAccountsData = await voteAccountsResponse.json();
      const activeValidators = voteAccountsData.result.current || [];
      const totalValidators = activeValidators.length;
      const totalStake = activeValidators.reduce((sum, val) => sum + val.activatedStake, 0) / 1e9;

      const performanceResponse = await fetch(RPC_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'getRecentPerformanceSamples',
          params: [100],
        }),
      });
      const performanceData = await performanceResponse.json();
      const samples = performanceData.result || [];

      const tpsHistory = samples.map((sample) => ({
        slot: sample.slot,
        tps: sample.numTransactions / sample.samplePeriodSecs,
      }));

      const currentTPS = tpsHistory.length > 0 ? tpsHistory[0].tps : 0;

      setNetworkData({
        totalValidators,
        totalStake,
        currentTPS,
        tpsHistory,
        error: '',
      });
    } catch (error) {
      console.error('Error fetching network data:', error);
      setNetworkData((prevState) => ({
        ...prevState,
        error: 'Failed to fetch network data.',
      }));
    }
  };

  useEffect(() => {
    fetchNetworkData();
    const intervalId = setInterval(fetchNetworkData, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '50px', color: '#333' }}>
      {networkData.error ? (
        <p>{networkData.error}</p>
      ) : (
        <>
          <h2>Network Overview</h2>
          <p>Total Active Validators: {networkData.totalValidators}</p>
          <p>Total Stake: {networkData.totalStake.toFixed(2)} SOL</p>
          <p>Current TPS: {networkData.currentTPS.toFixed(2)}</p>

          <div style={{ marginTop: '20px', width: '80%', margin: '0 auto' }}>
            <h3>TPS Trend (Last 100 Samples)</h3>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={networkData.tpsHistory}
                margin={{ top: 20, right: 30, left: 20, bottom: 50 }} // Added bottom margin
              >
                <CartesianGrid strokeDasharray="3 3" vertical={true} />
                <XAxis
                  dataKey="slot"
                  tick={{ fontSize: 12, fill: '#666' }}
                  label={{
                    value: 'Slot',
                    position: 'insideBottom',
                    offset: -10, // Adjust offset for extra spacing
                    style: { fill: '#333' },
                  }}
                />
                <YAxis
                  tick={{ fontSize: 12, fill: '#666' }}
                  label={{
                    value: 'TPS',
                    angle: -90,
                    position: 'insideLeft',
                    style: { fill: '#333' },
                  }}
                />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar dataKey="tps" name="TPS" fill="#9b4de1" barSize={6} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default Network;

