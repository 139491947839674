import React, { useEffect, useState } from 'react';

const RPC_URL = 'https://rpc.owlnet.dev';

const EpochInfo = () => {
  const [epochData, setEpochData] = useState({
    epoch: 'Loading...',
    completedSlots: 0,
    slotsInEpoch: 0,
    remainingSlots: 0,
    startSlot: 0,
    endSlot: 0,
    blockHeight: 0,
    transactionCount: 0,
    secondsPerSlot: 0.4, // Default value for Solana
    timeLeft: 'Calculating...',
    startTime: null,
    endTime: null,
    error: '',
  });

  useEffect(() => {
    const fetchEpochInfo = async () => {
      try {
        const response = await fetch(RPC_URL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            jsonrpc: '2.0',
            id: 1,
            method: 'getEpochInfo',
            params: [],
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        const result = data.result || {};

        const epoch = result.epoch || 'N/A';
        const completedSlots = result.slotIndex || 0;
        const slotsInEpoch = result.slotsInEpoch || 500;
        const startSlot = result.absoluteSlot - completedSlots;
        const endSlot = startSlot + slotsInEpoch;
        const remainingSlots = slotsInEpoch - completedSlots;
        const secondsPerSlot = 0.4;

        // Calculate start and end times in Unix timestamp
        const currentTimestamp = Date.now();
        const startTime = new Date(currentTimestamp - completedSlots * secondsPerSlot * 1000);
        const endTime = new Date(startTime.getTime() + slotsInEpoch * secondsPerSlot * 1000);

        const timeLeftInSeconds = Math.floor(remainingSlots * secondsPerSlot);
        const formattedTimeLeft = formatTimeLeft(timeLeftInSeconds);

        setEpochData({
          epoch,
          completedSlots,
          slotsInEpoch,
          remainingSlots,
          startSlot,
          endSlot,
          blockHeight: result.blockHeight || 'N/A',
          transactionCount: result.transactionCount || 'N/A',
          secondsPerSlot,
          timeLeft: formattedTimeLeft,
          startTime,
          endTime,
        });
      } catch (error) {
        console.error('Error fetching epoch info:', error);
        setEpochData((prevState) => ({
          ...prevState,
          error: 'Failed to fetch epoch information.',
        }));
      }
    };

    fetchEpochInfo();
    const intervalId = setInterval(fetchEpochInfo, 1000);

    // Update the time left every second
    const timerId = setInterval(() => {
      setEpochData((prevState) => {
        const timeLeftInSeconds = Math.max(
          Math.floor(prevState.remainingSlots * prevState.secondsPerSlot) - 1,
          0
        );
        return {
          ...prevState,
          timeLeft: formatTimeLeft(timeLeftInSeconds),
        };
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
      clearInterval(timerId);
    };
  }, []);

  const formatTimeLeft = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs}h ${mins}m ${secs}s`;
  };

  const formatTime = (date) => {
    return date ? date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : 'Calculating...';
  };

  const progressPercentage = ((epochData.completedSlots / epochData.slotsInEpoch) * 100).toFixed(2);

  return (
    <div style={{ textAlign: 'center', marginTop: '20px', color: '#333', paddingBottom: '40px' }}>
      {epochData.error ? (
        <p>{epochData.error}</p>
      ) : (
        <>
          <p>Epoch: {epochData.epoch}</p>
          <p>Progress: {progressPercentage}% complete</p>
          <p>Remaining Slots: {epochData.remainingSlots}</p>
          <p>Completed Slots: {epochData.completedSlots} / {epochData.slotsInEpoch}</p>
          <p>Slot Range: [{epochData.startSlot}..{epochData.endSlot}]</p>
          <p>Block Height: {epochData.blockHeight}</p>
          <p>Transaction Count: {epochData.transactionCount}</p>
          <p>Remaining Time: {epochData.timeLeft}</p>
          <div
            style={{
              margin: '20px auto',
              width: '80%',
              height: '30px',
              backgroundColor: '#eee',
              borderRadius: '15px',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                height: '100%',
                width: `${progressPercentage}%`,
                background: 'linear-gradient(to right, purple, pink, red)',
                transition: 'width 0.5s ease',
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', margin: '0 auto', color: '#666' }}>
            <span>Start Time: {formatTime(epochData.startTime)}</span>
            <span>End Time: {formatTime(epochData.endTime)}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default EpochInfo;
