import React from 'react';
import './HeroSection.css';
import logo from '../assets/owl-icon.svg';

const HeroSection = () => {
  // Function to copy the RPC link to the clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText('https://rpc.owlnet.dev');
    alert('RPC link copied to clipboard!');
  };

  return (
    <div className="hero-section">
      {/* Header Section */}
      <div className="header">
        <div className="logo-title">
          <img src={logo} alt="Owl Logo" className="logo" />
          <a
            href="/"
            className="header-title"
            style={{
              textDecoration: 'none', // No underline
              color: 'white',         // White font
              marginLeft: '10px',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            OWLNET
          </a>
        </div>
    </div>
      {/* Main Content */}
      <div className="content">
        <h1 className="main-text">OWL</h1>
        <h2 className="subtitle">X1 Validator</h2>
        <p className="description">Powering the Future of the X1 Network</p>
        <div className="buttons">
          <a
            href="https://docs.x1.xyz"
            target="_blank"
            rel="noopener noreferrer"
            className="btn"
          >
            Documentation
          </a>
          <button onClick={copyToClipboard} className="btn">
            RPC
          </button>
          <a
            href="/delegate"
            target="_blank"
            rel="noopener noreferrer"
            className="btn"
          >
            Delegate
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

