import React, { useState, useEffect } from 'react';

const RPC_URL = 'https://rpc.owlnet.dev'; // Update if needed

const GossipInfo = () => {
  const [gossipData, setGossipData] = useState([]);
  const [voteAccountsData, setVoteAccountsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('desc'); // Sort order: 'asc' or 'desc'
  const [error, setError] = useState('');

  const fetchGossipData = async () => {
    try {
      const response = await fetch(RPC_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'getClusterNodes',
          params: [],
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch gossip data');
      }

      const data = await response.json();
      setGossipData(data.result || []);
    } catch (error) {
      console.error('Error fetching gossip data:', error);
      setError('Failed to retrieve gossip data.');
    }
  };

  const fetchVoteAccountsData = async () => {
    try {
      const response = await fetch(RPC_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          jsonrpc: '2.0',
          id: 1,
          method: 'getVoteAccounts',
          params: [],
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch vote accounts data');
      }

      const data = await response.json();
      setVoteAccountsData(data.result.current || []);
    } catch (error) {
      console.error('Error fetching vote accounts data:', error);
      setError('Failed to retrieve vote accounts data.');
    }
  };

  useEffect(() => {
    fetchGossipData();
    fetchVoteAccountsData();
  }, []);

  const getDelinquencyStatus = (lastVote) => {
    const currentSlot = 45530211; // Replace with dynamic slot if possible
    return currentSlot - lastVote > 100; // Example threshold for delinquency
  };

  const combinedData = gossipData.map((node) => {
    const voteAccount = voteAccountsData.find((vote) => vote.nodePubkey === node.pubkey);
    return {
      nodePubkey: node.pubkey,
      gossip: node.gossip,
      version: node.version,
      activatedStake: voteAccount ? voteAccount.activatedStake : 0,
      commission: voteAccount ? `${voteAccount.commission}%` : 'N/A',
      votePubkey: voteAccount ? voteAccount.votePubkey : 'N/A',
      lastVote: voteAccount ? voteAccount.lastVote : 'N/A',
      delinquent: voteAccount ? getDelinquencyStatus(voteAccount.lastVote) : true,
    };
  });

  const totalValidators = combinedData.length;
  const totalActive = combinedData.filter((validator) => !validator.delinquent).length;
  const totalNonActive = totalValidators - totalActive;
  const totalStake = combinedData.reduce((sum, validator) => sum + validator.activatedStake, 0);
  const validatorsByVersion = combinedData.reduce((acc, validator) => {
    acc[validator.version] = (acc[validator.version] || 0) + 1;
    return acc;
  }, {});

  const sortedData = [...combinedData]
    .filter((validator) => {
      return (
        validator.nodePubkey.includes(searchQuery) ||
        validator.votePubkey.includes(searchQuery) ||
        validator.gossip.includes(searchQuery)
      );
    })
    .sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.activatedStake - b.activatedStake;
      } else {
        return b.activatedStake - a.activatedStake;
      }
    });

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  return (
    <div style={{ padding: '20px', textAlign: 'left' }}>
      <h2>X1 Validator Information</h2>
      {error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <>
          <div style={{ marginBottom: '20px' }}>
            <p>
              <strong>Total Validators:</strong> {totalValidators}
            </p>
            <p>
              <strong>Total Active Validators:</strong> {totalActive}
            </p>
            <p>
              <strong>Total Non-Active Validators:</strong> {totalNonActive}
            </p>
            <p>
              <strong>Total Activated Stake:</strong> {totalStake.toLocaleString()}
            </p>
            <p>
              <strong>Validators by Version:</strong>
              {Object.entries(validatorsByVersion).map(([version, count]) => (
                <span key={version} style={{ marginLeft: '10px' }}>
                  {version}: {count}
                </span>
              ))}
            </p>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <input
              type="text"
              placeholder="Search by Node Pubkey, Vote Pubkey, or Gossip Address"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                marginRight: '10px',
                padding: '5px',
                fontSize: '14px',
              }}
            />
          </div>

          <div style={{ overflowX: 'auto', margin: '20px auto', width: '100%' }}>
            <table
              style={{
                borderCollapse: 'collapse',
                width: '100%',
                whiteSpace: 'nowrap',
                fontSize: '12px', // Smaller font size
                border: '1px solid darkgray',
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: '1px solid darkgray', padding: '10px', textAlign: 'left' }}>
                    Node Pubkey
                  </th>
                  <th style={{ border: '1px solid darkgray', padding: '10px', textAlign: 'left' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      Activated Stake
                      <button
                        onClick={toggleSortOrder}
                        style={{
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '12px',
                          padding: 0,
                        }}
                        title="Sort by stake"
                      >
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </button>
                    </div>
                  </th>
                  <th style={{ border: '1px solid darkgray', padding: '10px', textAlign: 'left' }}>
                    Commission
                  </th>
                  <th style={{ border: '1px solid darkgray', padding: '10px', textAlign: 'left' }}>
                    Vote Pubkey
                  </th>
                  <th style={{ border: '1px solid darkgray', padding: '10px', textAlign: 'left' }}>
                    Last Vote
                  </th>
                  <th style={{ border: '1px solid darkgray', padding: '10px', textAlign: 'left' }}>
                    Gossip Address
                  </th>
                  <th style={{ border: '1px solid darkgray', padding: '10px', textAlign: 'left' }}>
                    Version
                  </th>
                  <th style={{ border: '1px solid darkgray', padding: '10px', textAlign: 'left' }}>
                    Delinquency Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((validator, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: validator.delinquent ? '#d3d3d3' : 'white',
                    }}
                  >
                    <td style={{ border: '1px solid darkgray', padding: '10px' }}>
                      {validator.nodePubkey}
                    </td>
                    <td style={{ border: '1px solid darkgray', padding: '10px' }}>
                      {validator.activatedStake.toLocaleString()}
                    </td>
                    <td style={{ border: '1px solid darkgray', padding: '10px' }}>
                      {validator.commission}
                    </td>
                    <td style={{ border: '1px solid darkgray', padding: '10px' }}>
                      {validator.votePubkey}
                    </td>
                    <td style={{ border: '1px solid darkgray', padding: '10px' }}>
                      {validator.lastVote}
                    </td>
                    <td style={{ border: '1px solid darkgray', padding: '10px' }}>
                      {validator.gossip}
                    </td>
                    <td style={{ border: '1px solid darkgray', padding: '10px' }}>
                      {validator.version}
                    </td>
                    <td style={{ border: '1px solid darkgray', padding: '10px' }}>
                      {validator.delinquent ? 'Delinquent' : 'Active'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default GossipInfo;
