import React, { useEffect, useState } from "react";
import { Progress } from "antd";

const RPC_URL = "https://rpc.owlnet.dev";

const CurrentStatus = () => {
  const [statusData, setStatusData] = useState({
    currentSlot: 0,
    nextLeaderSlot: "N/A",
    timeUntilNextLeader: "N/A",
    voteStatus: "Unknown",
  });

  const [timeLeft, setTimeLeft] = useState(0); // Timer for the progress bar

  const slotDurationSeconds = 0.4; // Average Solana slot duration in seconds

  // Fetch the current slot
  const fetchCurrentSlot = async () => {
    try {
      const slotResponse = await fetch(RPC_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          jsonrpc: "2.0",
          id: 1,
          method: "getSlot",
          params: [],
        }),
      });
      const slotData = await slotResponse.json();
      return slotData.result;
    } catch (error) {
      console.error("Error fetching current slot:", error);
      return null;
    }
  };

  // Update the current status
  const updateStatus = async () => {
    const currentSlot = await fetchCurrentSlot();

    if (currentSlot !== null) {
      const nextLeaderSlot = currentSlot + 5; // Approximation: Next leader in 5 slots
      const timeUntilNextLeader =
        (nextLeaderSlot - currentSlot) * slotDurationSeconds;

      setStatusData({
        currentSlot,
        nextLeaderSlot,
        timeUntilNextLeader: timeUntilNextLeader.toFixed(2),
        voteStatus: "Active", // Assuming the current leader is active
      });

      // Initialize the timer countdown
      setTimeLeft(timeUntilNextLeader);
    }
  };

  // Timer countdown logic
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => Math.max(prevTimeLeft - 0.1, 0)); // Update every 100ms
      }, 100);

      return () => clearInterval(timer); // Cleanup interval
    } else {
      // Fetch new status once the timer reaches 0
      updateStatus();
    }
  }, [timeLeft]);

  useEffect(() => {
    // Fetch initial status and start updates
    updateStatus();
    const statusInterval = setInterval(updateStatus, 5000); // Refresh every 5 seconds
    return () => clearInterval(statusInterval);
  }, []);

  const progressPercent =
    statusData.timeUntilNextLeader !== "N/A"
      ? ((slotDurationSeconds * 5 - timeLeft) / (slotDurationSeconds * 5)) * 100
      : 0;

  return (
    <div style={{ textAlign: "center", marginTop: "20px", color: "#333" }}>
      <h2>Current Leader Status</h2>
      <p>Current Slot: {statusData.currentSlot}</p>
      <p>Next Leader Slot: {statusData.nextLeaderSlot}</p>
      <p>Vote Status: {statusData.voteStatus}</p>
      <p>
        Time Until Next Leader:{" "}
        {timeLeft > 0 ? `${timeLeft.toFixed(2)} seconds` : "Fetching..."}
      </p>

      {/* Progress Bar */}
      <div style={{ marginTop: "20px", marginBottom: "20px", width: "80%", margin: "0 auto" }}>
        <Progress
          percent={progressPercent.toFixed(2)}
          status="active"
          strokeWidth={12}
          showInfo={false}
          strokeColor={{
            "0%": "#9b4de1", // Purple
            "50%": "#e91e63", // Pink
            "100%": "#f44336", // Red
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            fontSize: "14px",
          }}
        >
          <span>Start</span>
          <span>End</span>
        </div>
      </div>
    </div>
  );
};

export default CurrentStatus;

