import axios from 'axios';

const RPC_URL = 'https://rpc.owlnet.dev'; 
const API_URL = process.env.REACT_APP_METRICS_API || 'https://rpc.owlnet.dev/metrics';

/** Helper function for making RPC requests */
const makeRpcRequest = async (method, params = []) => {
  const response = await fetch(RPC_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      jsonrpc: '2.0',
      id: 1,
      method,
      params,
    }),
  });

  const data = await response.json();
  if (data.error) {
    throw new Error(`RPC Error: ${data.error.message}`);
  }
  return data.result;
};


// Function to fetch recent performance samples and dynamically get the public key
export const getRecentPerformanceSamples = async (validatorPubkey) => {
  try {
    const response = await axios.post(RPC_URL, {
      jsonrpc: '2.0',
      id: 1,
      method: 'getRecentPerformanceSamples',
      params: [],
    });

    // Map the response data to include necessary information
    const slots = response.data.result.map((sample) => {
      // You can modify the response and get the public key here
      const publicKey = validatorPubkey; // If it's known, use it directly

      // For Block fees, we'll just use the total number of transactions
      const blockFees = sample.numTransactions; // Assuming fees are just total transactions here

      // Adjust duration (using average slot time as 400ms, or adapt as needed)
      const duration = 400; // 400ms per slot

      // Set status based on logic (can adjust depending on real status conditions)
      let status = 'pending'; // Default status is 'pending', can be dynamically updated

      return {
        number: sample.slot,
        votes: sample.numTransactions,
        nonVotes: sample.numNonVoteTransactions,
        fees: blockFees, // Use total transactions as block fees
        duration, // Adjusted to average slot time in ms
        computeUnits: sample.numSlots, // Example for compute units, adjust as necessary
        status, // Status, can be dynamically updated
        validatorPubkey: publicKey, // Add validator's public key here
      };
    });

    return {
      slots,
      validatorInfo: {
        pubkey: validatorPubkey,
        version: 'v1.0', // Example version, adjust as necessary
        stake: '1000 SOL', // Example stake, adjust as necessary
        ipAddress: '127.0.0.1', // Example IP address, adjust as necessary
      },
    };
  } catch (error) {
    console.error('Error fetching performance samples:', error);
    return null;
  }
};


/** Fetch detailed metrics */
export const fetchDetailedMetrics = async () => {
  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch metrics: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching detailed metrics:', error);
    return null;
  }
};

/** Fetch epoch info */
export const fetchEpochInfo = async () => {
  try {
    const result = await makeRpcRequest('getEpochInfo');
    console.log('Full Epoch Info Response:', { result }); // Debugging log

    const epoch = result.epoch || 'N/A';
    const completedSlots = result.slotIndex || 0;
    const slotsInEpoch = result.slotsInEpoch || 500;
    const startSlot = result.absoluteSlot - completedSlots;
    const endSlot = startSlot + slotsInEpoch;

    return {
      epoch,
      completedSlots,
      slotsInEpoch,
      remainingSlots: slotsInEpoch - completedSlots,
      startSlot,
      endSlot,
      blockHeight: result.blockHeight || 'N/A',
      transactionCount: result.transactionCount || 'N/A',
    };
  } catch (error) {
    console.error('Error fetching epoch info:', error);
    throw error;
  }
};

/** Fetch TPU metrics */
export const fetchTpuMetrics = async () => {
  try {     
    const schedule = await makeRpcRequest('getLeaderSchedule');
    console.log('TPU Metrics Response:', { schedule }); // Debugging log
    return { leaderSchedule: schedule || {} };
  } catch (error) {
    console.error('Error fetching TPU metrics:', error);
    throw error;
  }
};

/** Fetch current slot */
export const fetchCurrentSlot = async () => {
  try {
    const result = await makeRpcRequest('getSlot');
    console.log('Current Slot Response:', { result }); // Debugging log
    return result || 'N/A';
  } catch (error) {
    console.error('Error fetching current slot:', error);
    throw error;
  }
};

/** Fetch transaction count */
export const fetchTransactionCount = async () => {
  try {
    const result = await makeRpcRequest('getTransactionCount');
    console.log('Transaction Count Response:', { result }); // Debugging log
    return result || 0;
  } catch (error) {
    console.error('Error fetching transaction count:', error);
    throw error;
  }
};

/** Fetch general metrics */
export const fetchMetrics = async () => {
  try {
    const result = await makeRpcRequest('getTPUMetrics');
    return result || {};
  } catch (error) {
    console.error('Error fetching metrics:', error);
    // Fallback mechanism if the method is not found
    if (error.message.includes('Method not found')) {
      console.log('Method getTPUMetrics not found, returning empty metrics');
      return {}; // Fallback to empty object
    }
    return {};
  }
};;

/** New Validator Voting Functions */
export const getValidatorInfo = async (pubkey) => {
  try {
    const voteAccounts = await makeRpcRequest('getVoteAccounts');
    const validatorAccount = voteAccounts.current.find(
      account => account.votePubkey === pubkey
    );

    if (!validatorAccount) {
      throw new Error('Validator not found');
    }

    const version = await makeRpcRequest('getVersion');
    const clusterNodes = await makeRpcRequest('getClusterNodes');
    const nodeInfo = clusterNodes.find(
      node => node.pubkey === validatorAccount.nodePubkey
    );

    return {
      pubkey: pubkey,
      version: version['solana-core'],
      stake: `${(validatorAccount.activatedStake / 1e9).toFixed(2)} SOL`,
      ipAddress: nodeInfo?.gossip || 'Unknown'
    };
  } catch (error) {
    console.error('Error fetching validator info:', error);
    throw error;
  }
};

export const getRecentVotingActivity = async (pubkey, slotsToFetch = 10) => {   
  try {
    const currentSlot = await makeRpcRequest('getSlot');
    const startSlot = currentSlot - slotsToFetch;
    const slots = await makeRpcRequest('getBlocks', [startSlot, currentSlot]);

    const votingInfo = await Promise.all(
      slots.map(async (slot) => {
        try {
          const block = await makeRpcRequest('getBlock', [slot, {
            encoding: 'json',
            maxSupportedTransactionVersion: 0,
            transactionDetails: 'full',
            rewards: false
          }]);

          const votes = block.transactions.filter(tx =>
            tx.meta?.votes?.some(vote => vote.pubkey === pubkey)
          ).length;

          return {
            number: slot,
            votes: votes,
            nonVotes: block.transactions.length - votes,
            fees: (block.transactions.reduce((acc, tx) => acc + (tx.meta?.fee || 0), 0) / 1e9).toFixed(6),
            duration: block.blockTime ? `${(block.blockTime % 60).toFixed(2)}s` : 'N/A',
            computeUnits: block.transactions.reduce((acc, tx) => acc + (tx.meta?.computeUnitsConsumed || 0), 0),
            status: block.confirmed ? 'confirmed' : 'pending'
          };
        } catch (error) {
          console.error(`Error processing slot ${slot}:`, error);
          return {
            number: slot,
            votes: 0,
            nonVotes: 0,
            fees: '0',
            duration: 'N/A',
            computeUnits: 0,
            status: 'failed'
          };
        }
      })
    );

    return {
      slots: votingInfo,
      validatorInfo: await getValidatorInfo(pubkey)
    };
  } catch (error) {
    console.error('Error fetching voting activity:', error);
    throw error;
  }
};

/** Fetch recent performance samples */
export const fetchRecentPerformanceSamples = async () => {
  try {
    const result = await makeRpcRequest('getRecentPerformanceSamples');
    return result || [];
  } catch (error) {
    console.error('Error fetching recent performance samples:', error);
    return [];
  }
};

// Set up interval to fetch data every second (if needed)
setInterval(async () => {
  const metrics = await fetchMetrics();
  console.log(metrics);
}, 6000);
