import React, { useState, useEffect } from 'react';

const API_URL = 'https://rpc.owlnet.dev'; // Backend API URL

const VotingData = () => {
  const [logs, setLogs] = useState([]);
  const [error, setError] = useState(null);

  // Fetch logs from the API
  const fetchLogs = async () => {
    try {
      const response = await fetch(`${API_URL}/api/logs`); // Updated to /api/logs
      if (!response.ok) throw new Error(`Server error: ${response.status}`);

      const data = await response.json();
      setLogs(data.logs);
      setError(null);
    } catch (err) {
      console.error('Fetch error:', err);
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchLogs(); // Initial fetch
    const interval = setInterval(fetchLogs, 2000); // Poll every 5 seconds
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Solana Validator Logs</h1>
      {error ? (
        <p style={{ color: 'red' }}>Error: {error}</p>
      ) : (
        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '20px' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Timestamp</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Level</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Message</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.timestamp || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.level || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{log.message || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default VotingData;
